module.exports = [
  "anime-mad",
  "bandaid",
  "frame-blue-rose",
  "third-eye",
  "pearrot",
  "black-circle",
  "septum-ring",
  "cold-sweat",
  "egirl-hearts",
  "eyepatch",
  "freckles-2",
  "blue-hearts",
  "frame-caution",
  "heart-doodles",
  "heart-glasses",
  "necklace",
  "pastel-rainbow-cloud",
  "pink-hearts",
  "round-glasses",
  "silver-piercing-1",
  "silver-piercing-2",
  "sparkles-2",
  "blue-doodle",
  "pink-doodle",
  "yellow-doodle",
  "green-doodle",
  "yellow-hearts",
  "gold-piercing-1",
  "gold-piercing-2",
  "gold-piercing-3",
  "heart-barette",
  "lip-bite",
  "choker-2",
  "choker",
  "angel-halo",
  "angry-eyebrows",
  "blush-2",
  "frame-cherry-blossoms",
  "bright-pink-blush",
  "cat-ears",
  "cat-whiskers",
  "anime-mouth",
  "blush",
  "frame-clemantis",
  "dark-pink-heart",
  "cherries",
  "devil",
  "freckles",
  "bobby-pins",
  "frame-eyes",
  "light-pink-heart",
  "o-eyes",
  "panda_ears",
  "frame-rainbow",
  "pink-blush",
  "pink-bow",
  "red-hearts",
  "frame-sparkles",
  "salmon-heart",
  "sparkles",
  "w-lips",
  "x-eyes"
]